import { LAKEFORMATION_TAG_TYPE, REDSHIFT_TAG_TYPE } from 'src/commons/constants';
import { getPartition } from 'src/api/config';

export function isValidPrincipal(principal) {
  return /^arn:(aws|aws-cn|aws-iso(-([a-z]))?):iam::\d{12}:(?:root|user\/[\w+=,.@\-/]+|role\/[\w+=,.@\-/]+)$/.test(
    principal,
  );
}

export function isValidPrincipalNotRoot(principal) {
  return /^arn:(aws|aws-cn|aws-iso(-([a-z]))?):iam::\d{12}:(?:user\/[\w+=,.@\-/]+|role\/[\w+=,.@\-/]+)$/.test(
    principal,
  );
}

export function isValidIAMPrincipal(principal) {
  return /^\d{12}:(role|user):[\w+=,.@\-/]+$/.test(principal);
}

export function isValidGroupingMechanismId(id) {
  return /^[-a-zA-Z0-9]+$|^amzn1.abacus.team.[a-z0-9]+$/.test(id);
}

export function isValidRole(role) {
  return /^arn:(aws|aws-cn|aws-iso(-([a-z]))?):iam::\d{12}:role\/[\w+=,.@\-/]+$/.test(role);
}

export function isValidS3Path(path) {
  return /^s3:\/\/.+$/.test(path);
}

export function isValidSecurityGroup(securityGroup) {
  return /^sg-.+$/.test(securityGroup);
}

export function isValidKmsKey(key) {
  return /^(arn:(aws|aws-cn|aws-iso(-([a-z]))?):kms:(\w+|-)+:\d{12}:key\/((\w|\d)+|-)+)?$/.test(key);
}

export function isValidAccount(account) {
  return /^\d{12}$/.test(account);
}

export function isValidGroup(group) {
  return /^[\w-]{1,64}$/.test(group);
}

export function isValidWorkspace(workspaceId) {
  return /^(wks-)\d{12}(-)[a-z0-9]{7}$/.test(workspaceId);
}

export function isValidGalaxiGroup(galaxiGroupId: string) {
  return /^glx-.{3,}$/.test(galaxiGroupId);
}

export function isValidUser(user) {
  return /^[\w-]{1,64}$/.test(user);
}

export function isValidMidwayUser(user) {
  return /^([a-z]{1,64}|svc-mw-\d{12}-[0-9a-z-]{1,12})$/.test(user);
}

export function isValidAmazonEmail(email) {
  return /^([\w\.\-\+])+\@+([\w\.\-\+])+$/.test(email);
}

export function isRedshiftStandardIdentifier(name) {
  return /^[a-z_]+[a-z0-9_$]*$/.test(name);
}

export function isValidUrl(urlString) {
  let url;
  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

/**
 * Checks if the given value is falsy or not.
 * @param {*} value - The value to check.
 * @returns {boolean} True if the value is non-falsy, false otherwise.
 */
export function isNotFalsy(value: any): boolean {
  return !!value;
}

/**
 * Checks if the given value is considered non-empty.
 * @param {*} value - The value to check.
 * @returns {boolean} True if the value is non-empty, false otherwise.
 */
export function isNotEmpty(value: any): boolean {
  return value !== null && value !== undefined;
}

export function isFalsy(value: any): boolean {
  return !isNotFalsy(value);
}

export function isEmpty(value: any): boolean {
  return !isNotEmpty(value);
}

export function isNonNegativeInteger(number) {
  return parseInt(number) >= 0;
}

export function isPositiveInteger(number) {
  return parseInt(number) > 0;
}

export function isValidDataSetIdForAccountId(datasetId, accountId) {
  const regex = new RegExp('^DS-.*\\|A-' + accountId + '\\|DN-.*\\|TN-.*\\|R-.*$');
  return regex.test(datasetId);
}

export function isValidClusterUser(userName) {
  const regex = new RegExp('^[a-z_]+[w$]*$');
  return regex.test(userName);
}

export function isValidDataSetIdFormat(datasetId) {
  const regex = new RegExp('^DS-.*\\|A-.*\\|(CI-.*\\|)?DN-.*\\|(SN-.*\\|)?TN-.*\\|R-.*$');
  return regex.test(datasetId);
}

export function isValidHybridCatalogIdFormat(id) {
  const regex = new RegExp('^DS-.*\\|A-.*\\|(CI-.*\\|)?(DN-.*\\|)?(SN-.*\\|)?(TN-.*\\|)?R-.*$');
  const tagRegex = new RegExp('^.+:.+:.+:.+$');
  return regex.test(id) || tagRegex.test(id);
}

export function isValidTableName(tableName: string): boolean {
  return /^[a-zA-Z0-9_]+$/.test(tableName);
}

export function isValidDatabaseName(databaseName: string): boolean {
  return /^[a-zA-Z0-9_]+$/.test(databaseName);
}

export function isValidDatabasePrefix(databaseName: string): boolean {
  return databaseName.startsWith('aws_tethys');
}

export function isValidAccountId(accountId) {
  return /^\d{12}$/.test(accountId);
}

export function isValidDdbTableArn(ddbTableArn: string): boolean {
  return /^arn:(aws|aws-cn|aws-iso|aws-iso-[a-z]|aws-us-gov|aws-eusc):dynamodb:\w+(?:-\w+)+:\d{12}:table\/[A-Za-z0-9_+-]+$/.test(
    ddbTableArn,
  );
}

export function isValidS3BucketName(s3BucketName: string): boolean {
  return /(?!(^xn--|.+-s3alias$))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$/.test(s3BucketName);
}

export function isValidRegion(region) {
  return /^\w(\w|-)*$/.test(region);
}

export function isValidTimeFormat(dataLakeSLA) {
  return /^([01]?[0-9]|2[0-3]):[0-5][0-9](.*)$/.test(dataLakeSLA);
}

export function isValidSubnetId(subnetId) {
  return /^subnet\-.{5,17}$/.test(subnetId);
}

export function isValidSubnetIdList(subnetIdList) {
  return subnetIdList
    .split(',')
    .map((x) => x.trim())
    .filter((x) => !!x)
    .every(isValidSubnetId);
}

export function isValidEmrReleaseLabel(emrReleaseLabel) {
  return /^emr\-\d{1,2}\.\d{1,2}\.\d{1,2}[\w\-]*$/.test(emrReleaseLabel);
}

export function isValidIntegerList(integerList) {
  return !integerList.some(isNaN);
}

export function isValidJSONString(jsonString) {
  try {
    const obj = JSON.parse(jsonString);
    return obj.constructor == Object;
  } catch (err) {
    return false;
  }
}

export function isValidTagKey(tagKey) {
  /*
      Lake Formation "Single-line string pattern" restriction placed on LF Tag Keys: 
        https://tiny.amazon.com/xtkocsub/docsawsamazlakelatedgawsl
        https://tiny.amazon.com/1jtxx77a7/docsawsamazlakelatedghtml  
  
      The regex here is slightly different from the one in the AWS docs. 
  
      These are UTF16 defined regex's, but JavaScript uses UCS-2, so we change the rare endcase to a wider range.
      In the rare event we get a special character from a non-English language that also falls in the
      \uD800\uDC00-\uDC00\uDFFF range, we will error from the backend instead.
    */
  return /[\u0020-\uD7FF\uE000-\uFFFD\uD800-\uDFFF\t]{1,128}/.test(tagKey);
}

export function isValidTagValues(tagValues) {
  if (tagValues.length === 0) {
    return false;
  }

  return tagValues.every((tagValue) => {
    return /[\u0020-\uD7FF\uE000-\uFFFD\uD800-\uDFFF\t]{1,256}/.test(tagValue);
  });
}

export const hasNoSpaces = (str: string) => /^\S*$/.test(str);

export function isValidTagType(tagType) {
  return tagType == REDSHIFT_TAG_TYPE || tagType == LAKEFORMATION_TAG_TYPE;
}

export const validateTemplateUrl = (url: string) => {
  if (!url) {
    return true;
  }
  return url.includes('t.corp.amazon.com') || url.includes('sim.amazon.com') || url == '';
};

export const validateTicketUrl = (url: string) => {
  return validateTemplateUrl(url);
};

export const validateApprovalUrl = (url: string) => {
  if (!url) {
    return true;
  }
  return (
    url.includes('https://approvalstage.amazon.com') ||
    url.includes('https://approval.amazon.com') ||
    url.includes('https://approvals.amazon.com') ||
    url == ''
  );
};

export const isCommercialPartition = () => {
  return getPartition() === 'aws';
};
