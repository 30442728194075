export const DATASET_NODE_ID_PREFIX = 'dataset';
export const JOB_NODE_ID_PREFIX = 'job';
export const FACETS_KEY = 'facets';
// Sql facets
export const SQL_FACET_KEY = 'sql';
export const QUERY_FACET_KEY = 'query';
// Ownership facets
export const OWNERSHIP_FACET_KEY = 'ownership';
export const OWNERS_FACET_KEY = 'owners';
export const TYPE_FACET_KEY = 'type';
export const NAME_FACET_KEY = 'name';
// Schema facets
export const SCHEMA_FACET_KEY = 'schema';
export const FIELDS_FACET_KEY = 'fields';

export const PARALLEL_CALL_CHUCK_SIZE = 10;

// job types
export const EMR_JOB = 'elasticmapreduce';
export const GLUE_JOB = 'glue';
export const REDSHIFT_JOB = 'redshift';
export const RAM_JOB = 'ram';
export const CATALOG_SYNC = 'catalogSync';

export const IAM_CATALOG_IDS = ['377181497224', '213919596563'];
