import { listMetadataEntitiesAssociatedToResource, listMetadataFields, listMetadataValues } from 'src/api/catalog';
import { METADATA_FORM } from 'src/commons/constants';

export const listAllMetadataFormEntitiesAssociatedForResource = async (hcResourceArn: string) => {
  let formsForResource = [];
  let nextToken = undefined;

  do {
    let result = await listMetadataEntitiesAssociatedToResource({
      ResourceArn: hcResourceArn,
      MetadataEntityFilter: METADATA_FORM,
      NextToken: nextToken,
    });
    if (result?.FormList) {
      formsForResource.push(...result.FormList);
    }
    nextToken = result?.NextToken;
  } while (nextToken);
  return formsForResource;
};

export const getAllMetaDataFieldsForForm = async (formId: string) => {
  let formFields = [];
  let nextToken = undefined;

  do {
    let listMetadataFormFieldsResult = await listMetadataFields({
      MetadataFormId: formId,
      NextToken: nextToken,
    });
    if (listMetadataFormFieldsResult?.MetadataFieldsList) {
      formFields.push(...listMetadataFormFieldsResult.MetadataFieldsList);
    }
    nextToken = listMetadataFormFieldsResult?.NextToken;
  } while (nextToken);
  return formFields;
};

export const getAllMetaDataValuesForFormAndResource = async (formId: string, hcResourceArn: string) => {
  let valueList = [];
  let nextToken = undefined;
  do {
    let listMetadataValuesResult = await listMetadataValues({
      ResourceArn: hcResourceArn,
      MetadataFormId: formId,
      NextToken: nextToken,
    });
    if (listMetadataValuesResult?.MetadataValuesList) {
      valueList.push(...listMetadataValuesResult.MetadataValuesList);
    }
    nextToken = listMetadataValuesResult?.NextToken;
  } while (nextToken);
  return valueList;
};
