import * as React from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import {
  getDisplayKeyForNode,
  getDisplayNameForNode,
  lineageNodeHeightPx,
  lineageNodeWidthPx,
} from 'src/components/lineage/lineageUtil';
import { Container } from '@amzn/awsui-components-react-v3/polaris';
import { Box, KeyValuePairs } from '@amzn/awsui-components-react-v3';

const glueImage = require('./lineageImages/Glue.png');
const redshiftImage = require('./lineageImages/Redshift.png');
const s3Image = require('./lineageImages/S3.png');
const jobImage = require('./lineageImages/Job.png');
const customImage = require('./lineageImages/GenericAsset.png');

const getStylingForCustomNode = (nodeProps: NodeProps): React.CSSProperties => {
  return {
    width: lineageNodeWidthPx,
    height: lineageNodeHeightPx,
    cursor: 'pointer',
    padding: 0,
    scrollPadding: 0,
    boxShadow: nodeProps.selected ? '10px 5px 5px blue' : 'none',
    border: nodeProps?.data?.isBaseNode ? '8px solid green' : 'none', // to indicate base node
    borderLeft: nodeProps?.data?.isEndNode ? '4px solid orange' : 'none', // to indicate end nodes
    borderRight: nodeProps?.data?.isEndNode ? '4px solid orange' : 'none', // to indicate end nodes
  };
};

export const CustomDefaultNode: React.FC<NodeProps> = (nodeProps: NodeProps) => {
  return getNodeContainerWithBasicInfo(nodeProps, customImage);
};

export const GlueAssetNode: React.FC<NodeProps> = (nodeProps: NodeProps) => {
  return getNodeContainerWithBasicInfo(nodeProps, glueImage);
};

export const RedshiftAssetNode: React.FC<NodeProps> = (nodeProps: NodeProps) => {
  return getNodeContainerWithBasicInfo(nodeProps, redshiftImage);
};

export const S3AssetNode: React.FC<NodeProps> = (nodeProps: NodeProps) => {
  return getNodeContainerWithBasicInfo(nodeProps, s3Image);
};

export const JobNode: React.FC<NodeProps> = (nodeProps: NodeProps) => {
  return getNodeContainerWithBasicInfo(nodeProps, jobImage);
};

const getNodeContainerWithBasicInfo: React.FC<NodeProps> = (nodeProps: NodeProps, image) => {
  return (
    <div style={getStylingForCustomNode(nodeProps)}>
      <Container
        fitHeight
        media={{
          content: (
            <img
              src={image}
              alt='Img'
              style={{
                objectFit: 'contain',
                alignContent: 'center',
              }}
            />
          ),
          position: 'side',
          width: '25%',
        }}
      >
        <KeyValuePairs
          items={[
            {
              label: getDisplayKeyForNode(nodeProps?.id),
              value: (
                <Box fontWeight='bold' display='inline'>
                  {getDisplayNameForNode(nodeProps?.id)}
                </Box>
              ),
            },
          ]}
        />
        <Handle type={'target'} position={Position.Left} />
        <Handle type={'source'} position={Position.Right} />
      </Container>
    </div>
  );
};
