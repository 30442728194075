import * as React from 'react';
import { Page } from './Paths';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CreateSubscriptionComponent } from '../components/subscriptions/create';
import { CreateConnectionComponent } from '../components/connections/create';
import PublishDataSetComponent from '../components/catalog/publish';
import { PublisherAccounts } from '../components/publishTables/listAccounts';
import { RegisterPublisherAccount } from '../components/publishTables/registerAccount';
import { RegisterPublisherTable } from '../components/publishTables/registerTable';
import TableDetail from '../components/publishTables/tableDetail';
import BrowseTable from '../components/catalog/browsedatasets';
import PublishTablesComponent from '../components/publishTables/publishTables';
import { SubscriptionsTable } from '../components/subscriptions/subscriptions';
import { ConnectionsTable } from '../components/connections/connections';
import { ConnectionDetails } from '../components/connections/connection';
import { ExecutionsTable } from '../components/subscriptions/executions';
import CreateBackfillComponent from '../components/publishTables/createBackfill';
import PostDedupeComponent from '../components/publishTables/dedupe';
import MyRequests from 'src/components/requests/requests';
import { CreateNotificationForm } from 'src/components/notifications/create';
import { MyNotifications } from 'src/components/notifications/notificationsPage';
import { NotificationTemplateTable } from 'src/components/notifications/notificationTemplate/notificationTemplateList';
import { NotificationTemplateDetail } from 'src/components/notifications/notificationTemplate/notificationTemplateDetail';
import { NotificationTemplateCreateForm } from 'src/components/notifications/notificationTemplate/notificationTemplateCreateForm';
import { NotificationDetailPage } from 'src/components/notifications/detailPage/notificationDetailPage';
import { NotificationTemplateUpdateForm } from 'src/components/notifications/notificationTemplate/notificationTemplateUpdateForm';
import { NotificationBatchDetailPage } from 'src/components/notifications/detailPage/notificationBatchDetailPage';
import BrowseCatalogsTable from '../components/catalog/browsecatalogs';
import BrowseDatabasesTable from '../components/catalog/browsedatabases';
import CreateLakeFormationPermissionComponent from '../components/permissions/myPermissions/createLakeFormationPermissionsPage';
import { PermissionRequestDetails } from '../components/permissions/myPermissions/permissionRequestDetails';
import { DatasetsTable } from '../components/permissions/myDatasets/datasets';
import { DatasetsDetailsTable } from '../components/permissions/myDatasets/datasetsDetails';
import { MyDependencies } from '../components/dependencies/dependencies';
import { CreateDependency } from '../components/dependencies/create';
import { DependencyDetail } from '../components/dependencies/dependencyDetail';
import { MyResourceGroups } from '../components/resourcesmanager/resourcegroups/myResourceGroups';
import { ResourceGroupDetails } from '../components/resourcesmanager/resourcegroups/resourcegroupDetails';
import { ResourceDetails } from '../components/resourcesmanager/resourcegroups/resourceDetails';
import { ResourceGroupForm } from '../components/resourcesmanager/resourcegroups/resourcegroupForm';
import { BootstrapActionDetails } from '../components/resourcesmanager/bootstrapactions/bootstrapactionDetails';
import { BootstrapActionForm } from '../components/resourcesmanager/bootstrapactions/bootstrapactionForm';
import { TemplateForm } from '../components/resourcesmanager/templates/templateForm';
import { TemplateDetails } from '../components/resourcesmanager/templates/templateDetails';

// Ingestion
import { UpdateContract } from '../components/ingestion/registerContract/update';
import { RegisterDataset } from '../components/ingestion/registerContract';
import { DatasetDetail } from '../components/ingestion/datasetDetail';
import { Monitoring } from 'src/components/ingestion/monitoring';

// MetricStore
import { MetricsCatalog } from 'src/components/metricstore/metricsmetadata/metricsCatalog';
import { MetricDefinition } from 'src/components/metricstore/metricsmetadata/metricDefinition';
import { MetricCollectionsCatalog } from 'src/components/metricstore/metriccollection/metricCollectionsCatalog';
import { MetricCollectionDefinition } from 'src/components/metricstore/metriccollection/metricCollectionDefinition';
import { ReportingTableCatalog } from 'src/components/metricstore/reportingtable/reportingTableCatalog';
import { ReportingTableDefinition } from 'src/components/metricstore/reportingtable/reportingTableDefinition';
import { MonthlyView } from 'src/components/metricstore/metriccollection/monthlyView';
import { QuarterlyView } from 'src/components/metricstore/metriccollection/quarterlyView';
import { WeeklyView } from 'src/components/metricstore/metriccollection/weeklyView';
import { YearlyView } from 'src/components/metricstore/metriccollection/yearlyView';

import { Home } from '../components/home/home';
import { Checkout } from '../components/common/checkout';
import WorkspaceAccess from 'src/components/workspace_access/workspaceAccess';

import { MyBootstrapActions } from 'src/components/resourcesmanager/bootstrapactions/myBootstrapActions';
import { Baselining } from 'src/components/permissions/myBaselining/baselining';
import { NotificationConfiguration } from 'src/components/notifications/notificationConfiguration';
import { CreateWorkspace } from 'src/components/workspaces/workspaceManagement/create';
import { BrowseWorkspaces } from 'src/components/workspaces/workspaceManagement/browseWorkspaces';
import { WorkspaceUpdate } from 'src/components/workspaces/workspaceManagement/update';
import { WorkspaceDetailTab } from 'src/components/workspaces/workspaceManagement/detailTab';
import { AllPermissions } from 'src/components/workspaces/accessibleData/allPermissions';
import { CatalogDetail } from 'src/components/workspaces/dataBrowse/catalog/catalogDetail';
import { DatabaseDetail } from 'src/components/workspaces/dataBrowse/database/databaseDetail';
import { WSBrowseDatasets } from 'src/components/workspaces/dataBrowse/dataset/browseDatasets';
import { WSBrowseDatabases } from 'src/components/workspaces/dataBrowse/database/browseDatabases';
import { WSBrowseCatalogs } from 'src/components/workspaces/dataBrowse/catalog/browseCatalogs';
import { DatasetDetails } from 'src/components/workspaces/dataBrowse/dataset/datasetDetail';
import { WSMyRequestsDetails } from 'src/components/workspaces/requests/requestDetail';
import { WSMyRequests } from 'src/components/workspaces/requests/request';
import MyGroups from 'src/components/groups/groups';
import Tags from 'src/components/tags/tags';
import CreateTag from '../components/tags/createTag';
import EditTag from '../components/tags/editTag';
import { TemplatesTable } from '../components/templates/templates';
import { CreateTemplateComponent } from '../components/templates/create';
import { TemplateView } from '../components/templates/template';
import { EditTemplate } from '../components/templates/edit';
import { CurrentWorkspace } from 'src/components/workspaces/workspaceManagement/currentWorkspace';
import { LfPermissionDetail } from 'src/components/workspaces/accessibleData/lfPermissionDetail';
import { WorkspaceResourceLinks } from 'src/components/workspaces/analytics/athenaQuery';
import { WorkspaceRedshiftRegister } from 'src/components/workspaces/dataRegister/redshift/registerData';
import { WorkspaceGlueRegister } from 'src/components/workspaces/dataRegister/glue/registerData';
import { WorkspaceSchemaDetail } from 'src/components/workspaces/dataBrowse/schema/schemaDetail';
import { WorkspaceQueryEditor } from 'src/components/workspaces/analytics/redshiftQueryEditor';
import { WorkspaceRedshiftCatalogDetail } from 'src/components/workspaces/dataBrowse/catalog/redshiftCatalogDetail';
import { WorkspaceRedshiftDatabaseDetail } from 'src/components/workspaces/dataBrowse/database/redshiftDatabaseDetail';
import { RedshiftPermissionDetail } from 'src/components/workspaces/accessibleData/redshiftPermissionDetail';
import { WorkspaceDetailPage } from 'src/components/workspaces/dataBrowse/search/workspaceDetail';
import { WorkspaceTagDetail } from 'src/components/workspaces/dataBrowse/search/workspaceTagDetail';
import { PublishedPermissionDetail } from 'src/components/workspaces/accessibleData/publishedPermissionDetail';
import { CreateNewGroupForm } from 'src/components/common/createNewGroupForm';
import { FineGrainAccessPolicies } from 'src/components/workspaceFineGrainAccess/fineGrainAccessPolicies';
import CreateFgaPolicy from 'src/components/workspaceFineGrainAccess/createFgaPolicy';
import EditFgaPolicy from 'src/components/workspaceFineGrainAccess/editFgaPolicy';
import ViewFgaPolicy from 'src/components/workspaceFineGrainAccess/viewFgaPolicy';
import { BaselineDataAccessPermission } from 'src/components/permissions/myBaselining/baselineDataAccessPermission';
import { BaselineOwner } from 'src/components/permissions/myBaselining/baselineOwner';
import { EventSubscriptions } from 'src/components/catalog/subscriptions/eventSubscriptions';
import { EventSubscriptionDetails } from 'src/components/catalog/subscriptions/eventSubscriptionDetails';
import MetaDataForms from 'src/components/metadata/metadataForms/metadataForms';
import CreateMetadataForm from 'src/components/metadata/metadataForms/createMetadataForm';
import ViewMetadataForm from 'src/components/metadata/metadataForms/viewMetadataForm';
import MetadataBusinessGlossaries from 'src/components/metadata/businessGlossaries/metadataBusinessGlossaries';
import CreateBusinessGlossary from 'src/components/metadata/businessGlossaries/createBusinessGlossary';
import EditMetadataForm from 'src/components/metadata/metadataForms/editMetadataForm';
import EditBusinessGlossary from 'src/components/metadata/businessGlossaries/editBusinessGlossary';
import ViewBusinessGlossary from 'src/components/metadata/businessGlossaries/viewBusinessGlossary';
import { ListAdvisoriesPage } from 'src/components/dataadvisory/listAdvisoriesPage/listAdvisoriesPage';
import { AdvisoryDetailPage } from 'src/components/dataadvisory/advisoryDetailsPage/advisoryDetailPage';
import { CreateAdvisoryPage } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/createAdvisoryPage';
import { UpdateAdvisoryPage } from 'src/components/dataadvisory/createOrUpdateAdvisoryPage/updateAdvisoryPage';
import { ManageTemplatesPage } from 'src/components/dataadvisory/templates/manageTemplatesPage';
import { UpdateTemplatePage } from 'src/components/dataadvisory/templates/updateTemplatePage';
import { TemplateDetailPage } from 'src/components/dataadvisory/templates/templateDetailPage';
import { SendNotificationPage } from 'src/components/dataadvisory/sendNotificationPage/sendNotificationPage';
import { SearchHome } from 'src/components/search/search-home';
import { enableAdvisories } from 'src/api/config';

export const Routes = (props) => (
  <Switch>
    <Route path={[Page.HOME]} exact render={(route_props) => <Home {...route_props} {...props} />} />
    {!props.allowListed && (
      <Route
        path={[Page.ONBOARDING]}
        exact
        render={(route_props) => (
          <CreateNewGroupForm {...route_props} {...props} allowlisted={false} cancelRedirect={Page.HOME} />
        )}
      />
    )}
    <Route
      path={[Page.CREATE_NEW_GROUP]}
      exact
      render={(route_props) => (
        <CreateNewGroupForm {...route_props} {...props} allowlisted={true} cancelRedirect={Page.GROUP} />
      )}
    />
    <Route
      path={Page.BROWSE_CATALOGS}
      exact
      render={(route_props) => <BrowseCatalogsTable {...route_props} {...props} title='Catalogs' />}
    />
    <Route
      path={[Page.CATALOG_DETAILS, Page.DEPRECATED_WKS_CATALOG_DETAILS]}
      exact
      render={(route_props) => <CatalogDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.BROWSE_DATABASES}
      exact
      render={(route_props) => <BrowseDatabasesTable {...route_props} {...props} title='Databases' />}
    />
    <Route
      path={[Page.DATABASE_DETAILS, Page.DEPRECATED_WKS_DATABASE_DETAILS]}
      exact
      render={(route_props) => <DatabaseDetail {...route_props} {...props} />}
    />

    <Route
      path={[Page.SCHEMA_DETAILS, Page.DEPRECATED_WKS_SCHEMA_DETAILS]}
      exact
      render={(route_props) => <WorkspaceSchemaDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.BROWSE_DATASETS}
      exact
      render={(route_props) => (
        <BrowseTable {...route_props} {...props} title='Datasets' extraFeatures={true} disableFiltering={true} />
      )}
    />
    <Route
      path={[Page.DATASET_DETAILS, Page.DEPRECATED_WKS_DATASET_DETAILS]}
      exact
      render={(route_props) => <DatasetDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.SUBSCRIPTIONS}
      exact
      render={(route_props) => <SubscriptionsTable {...route_props} {...props} />}
    />
    <Route
      path={[Page.CREATE_SUBSCRIPTION]}
      exact
      render={(route_props) => <CreateSubscriptionComponent {...route_props} {...props} />}
    />
    <Route
      path={Page.SUBSCRIPTION_DETAILS}
      exact
      render={(route_props) => <ExecutionsTable {...route_props} {...props} />}
    />

    <Route path={Page.CONNECTIONS} exact render={(route_props) => <ConnectionsTable {...route_props} {...props} />} />
    <Route
      path={Page.CREATE_CONNECTION}
      exact
      render={(route_props) => <CreateConnectionComponent {...route_props} {...props} />}
    />
    <Route
      path={Page.CONNECTION_DETAILS}
      exact
      render={(route_props) => <ConnectionDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.PUBLISH}
      exact
      render={(route_props) => <PublishDataSetComponent {...route_props} {...props} />}
    />

    <Route
      path={Page.PUBLISHER_TABLES}
      exact
      render={(route_props) => <PublishTablesComponent {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_PUBLISHER_TABLE}
      exact
      render={(route_props) => <RegisterPublisherTable {...route_props} {...props} />}
    />
    <Route
      path={Page.PUBLISHER_CREATE_BACKFILL}
      exact
      render={(route_props) => <CreateBackfillComponent {...route_props} {...props} />}
    />
    <Route
      path={Page.PUBLISHER_POST_DEDUPE}
      exact
      render={(route_props) => <PostDedupeComponent {...route_props} {...props} />}
    />
    <Route
      path={Page.PUBLISHER_TABLE_DETAILS}
      exact
      render={(route_props) => <TableDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.PUBLISHER_ACCOUNTS}
      exact
      render={(route_props) => <PublisherAccounts {...route_props} {...props} />}
    />
    <Route
      path={Page.PUBLISHER_REGISTER_ACCOUNT}
      exact
      render={(route_props) => <RegisterPublisherAccount {...route_props} {...props} />}
    />

    <Route path={Page.MY_DATASETS} exact render={(route_props) => <DatasetsTable {...route_props} {...props} />} />
    <Route
      path={Page.CREATE_LAKE_FORMATION_PERMISSIONS}
      exact
      render={(route_props) => (
        <CreateLakeFormationPermissionComponent {...route_props} {...props} permissionType='request' />
      )}
    />
    <Route
      path={Page.DATASETSHARE_DETAILS}
      exact
      render={(route_props) => <DatasetsDetailsTable {...route_props} {...props} />}
    />

    <Route path={Page.REQUESTS} exact render={(route_props) => <MyRequests {...route_props} {...props} />} />
    <Route
      path={Page.REQUEST_DETAILS}
      exact
      render={(route_props) => <PermissionRequestDetails {...route_props} {...props} />}
    />

    <Route path={Page.DEPENDENCIES} exact render={(route_props) => <MyDependencies {...route_props} {...props} />} />
    <Route
      path={Page.CREATE_DEPENDENCY}
      exact
      render={(route_props) => <CreateDependency {...route_props} {...props} />}
    />
    <Route
      path={Page.DEPENDENCY_DETAILS}
      exact
      render={(route_props) => <DependencyDetail {...route_props} {...props} />}
    />

    <Route path={Page.GROUP} exact render={(route_props) => <MyGroups {...route_props} {...props} />} />
    <Route path={Page.GROUPS} exact render={(route_props) => <MyGroups {...route_props} {...props} />} />

    <Route
      path={Page.WORKSPACEACCESS}
      exact
      render={(route_props) => <WorkspaceAccess {...route_props} {...props} />}
    />

    <Route path={Page.TAGS} exact render={(route_props) => <Tags {...route_props} {...props} />} />
    <Route path={Page.CREATE_TAGS_TEMPLATE} exact render={(route_props) => <CreateTag {...route_props} {...props} />} />
    <Route path={Page.EDIT_TAGS_TEMPLATE} exact render={(route_props) => <EditTag {...route_props} {...props} />} />

    <Route
      path={Page.FINE_GRAIN_ACCESS_POLICIES}
      exact
      render={(route_props) => <FineGrainAccessPolicies {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_FINE_GRAIN_ACCESS_POLICY}
      exact
      render={(route_props) => <CreateFgaPolicy {...route_props} {...props} />}
    />
    <Route
      path={Page.EDIT_FINE_GRAIN_ACCESS_POLICY}
      exact
      render={(route_props) => <EditFgaPolicy {...route_props} {...props} />}
    />
    <Route
      path={Page.VIEW_FINE_GRAIN_ACCESS_POLICY}
      exact
      render={(route_props) => <ViewFgaPolicy {...route_props} {...props} />}
    />

    {/* START OF ADMIN ONLY ROUTES:  */}

    <Route
      path={Page.RESOURCEGROUPS}
      exact
      render={(route_props) => <MyResourceGroups {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_RESOURCEGROUP}
      exact
      render={(route_props) => <ResourceGroupForm {...route_props} {...props} />}
    />
    <Route
      path={Page.UPDATE_RESOURCEGROUP}
      exact
      render={(route_props) => <ResourceGroupForm {...route_props} {...props} isUpdate />}
    />
    <Route
      path={Page.RESOURCEGROUP_DETAILS}
      exact
      render={(route_props) => <ResourceGroupDetails {...route_props} {...props} />}
    />
    <Route
      path={Page.RESOURCE_DETAILS}
      exact
      render={(route_props) => <ResourceDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.EVENT_SUBSCRIPTIONS}
      exact
      render={(route_props) => <EventSubscriptions {...route_props} {...props} />}
    />
    <Route
      path={Page.EVENT_SUBSCRIPTIONS_DETAILS}
      exact
      render={(route_props) => <EventSubscriptionDetails {...route_props} {...props} />}
    />

    <Route
      path={Page.BOOTSTRAPACTIONS}
      exact
      render={(route_props) => <MyBootstrapActions {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_BOOTSTRAPACTION}
      exact
      render={(route_props) => <BootstrapActionForm {...route_props} {...props} />}
    />
    <Route
      path={Page.UPDATE_BOOTSTRAPACTION}
      exact
      render={(route_props) => <BootstrapActionForm {...route_props} {...props} isUpdate />}
    />
    <Route
      path={Page.BOOTSTRAPACTION_DETAILS}
      exact
      render={(route_props) => <BootstrapActionDetails {...route_props} {...props} />}
    />
    <Route path={Page.TAG_DETAIL} exact render={(route_props) => <WorkspaceTagDetail {...route_props} {...props} />} />

    <Route
      path={Page.CREATE_RG_TEMPLATE}
      exact
      render={(route_props) => <TemplateForm {...route_props} {...props} />}
    />
    <Route
      path={Page.UPDATE_RG_TEMPLATE}
      exact
      render={(route_props) => <TemplateForm {...route_props} {...props} isUpdate />}
    />
    <Route
      path={Page.RG_TEMPLATE_DETAILS}
      exact
      render={(route_props) => <TemplateDetails {...route_props} {...props} />}
    />

    {/* END OF ADMIN ONLY ROUTES */}

    {/*MetricStore  */}
    <Route path={Page.METRICS_CATALOG} exact render={(route_props) => <MetricsCatalog {...route_props} {...props} />} />

    <Route
      path={Page.METRIC_DEFINITION}
      exact
      render={(route_props) => <MetricDefinition {...route_props} {...props} />}
    />

    <Route
      path={Page.METRIC_COLLECTIONS_CATALOG}
      exact
      render={(route_props) => <MetricCollectionsCatalog {...route_props} {...props} />}
    />

    <Route
      path={Page.REPORTING_TABLE_CATALOG}
      exact
      render={(route_props) => <ReportingTableCatalog {...route_props} {...props} />}
    />

    <Route
      path={Page.REPORTING_TABLE_DEFINITION}
      exact
      render={(route_props) => <ReportingTableDefinition {...route_props} {...props} />}
    />

    <Route
      path={Page.METRIC_COLLECTIONS_DEFINITION}
      exact
      render={(route_props) => <MetricCollectionDefinition {...route_props} {...props} />}
    />

    <Route
      path={Page.METRIC_COLLECTION_WEEKLY_VIEW}
      exact
      render={(route_props) => <WeeklyView {...route_props} {...props} />}
    />

    <Route
      path={Page.METRIC_COLLECTION_MONTHLY_VIEW}
      exact
      render={(route_props) => <MonthlyView {...route_props} {...props} />}
    />

    <Route
      path={Page.METRIC_COLLECTION_QUARTERLY_VIEW}
      exact
      render={(route_props) => <QuarterlyView {...route_props} {...props} />}
    />

    <Route
      path={Page.METRIC_COLLECTION_YEARLY_VIEW}
      exact
      render={(route_props) => <YearlyView {...route_props} {...props} />}
    />

    <Route path={Page.CHECKOUT} exact render={(route_props) => <Checkout {...route_props} {...props} />} />

    <Route path={Page.NOTIFICATIONS} exact render={(route_props) => <MyNotifications {...route_props} {...props} />} />
    <Route
      path={[Page.CREATE_NOTIFICATION]}
      exact
      render={(route_props) => <CreateNotificationForm {...route_props} {...props} />}
    />
    <Route
      path={[Page.NOTIFICATION_DETAILS]}
      exact
      render={(route_props) => <NotificationDetailPage {...route_props} {...props} />}
    />
    <Route
      path={[Page.NOTIFICATION_BATCH_DETAILS]}
      exact
      render={(route_props) => <NotificationBatchDetailPage {...route_props} {...props} />}
    />

    <Route
      path={[Page.NOTIFICATION_TEMPLATES]}
      exact
      render={(route_props) => <NotificationTemplateTable {...route_props} {...props} />}
    />
    <Route
      path={[Page.CREATE_NOTIFICATION_TEMPLATE]}
      exact
      render={(route_props) => <NotificationTemplateCreateForm {...route_props} {...props} />}
    />
    <Route
      path={[Page.UPDATE_NOTIFICATION_TEMPLATE]}
      exact
      render={(route_props) => <NotificationTemplateUpdateForm {...route_props} {...props} />}
    />
    <Route
      path={[Page.NOTIFICATION_TEMPLATE_DETAILS]}
      exact
      render={(route_props) => <NotificationTemplateDetail {...route_props} {...props} />}
    />
    <Route
      path={[Page.NOTIFICATION_CONFIGURATION]}
      exact
      render={(route_props) => <NotificationConfiguration {...route_props} {...props} />}
    />
    <Route path={Page.BASELINING} exact render={(route_props) => <Baselining {...route_props} {...props} />} />
    <Route
      path={Page.BASELINE_DATA_ACCESS_PERMISSION}
      exact
      render={(route_props) => <BaselineDataAccessPermission {...route_props} {...props} />}
    />
    <Route path={Page.BASELINE_OWNER} exact render={(route_props) => <BaselineOwner {...route_props} {...props} />} />
    {enableAdvisories() && [
      <Route
        path={Page.LIST_ADVISORIES}
        exact
        render={(route_props) => <ListAdvisoriesPage {...route_props} {...props} />}
      />,
      <Route
        path={Page.CREATE_ADVISORY}
        exact
        render={(route_props) => <CreateAdvisoryPage {...route_props} {...props} />}
      />,
      <Route
        path={Page.CREATE_ADVISORY_FROM_TEMPLATE}
        render={(route_props) => <CreateAdvisoryPage {...route_props} {...props} />}
      />,
      <Route
        path={Page.CREATE_ADVISORY_FROM_ADVISORY}
        render={(route_props) => <CreateAdvisoryPage {...route_props} {...props} />}
      />,
      <Route
        path={Page.UPDATE_ADVISORY}
        exact
        render={(route_props) => <UpdateAdvisoryPage {...route_props} {...props} />}
      />,
      <Route
        path={Page.ADVISORY_SEND_NOTIFICATION}
        exact
        render={(route_props) => <SendNotificationPage {...route_props} {...props} />}
      />,
      <Route
        path={Page.LIST_ADVISORY_TEMPLATES}
        exact
        render={(route_props) => <ManageTemplatesPage {...route_props} {...props} />}
      />,
      <Route
        path={Page.UPDATE_ADVISORY_TEMPLATE}
        exact
        render={(route_props) => <UpdateTemplatePage {...route_props} {...props} />}
      />,
      <Route
        path={Page.ADVISORY_TEMPLATE_DETAIL}
        exact
        render={(route_props) => <TemplateDetailPage {...route_props} {...props} />}
      />,
      <Route
        path={Page.ADVISORY_DETAIL}
        exact
        render={(route_props) => <AdvisoryDetailPage {...route_props} {...props} />}
      />,
    ]}

    <Route
      exact
      path={[Page.INGESTION.REGISTER_CONTRACT]}
      render={() => (
        <RegisterDataset
          username={props.username}
          activeGroup={props.activeGroup}
          toggleHelp={props.toggleHelp}
          setContentType={props.setContentType}
          setNotifications={props.setNotifications}
        />
      )}
    />
    <Route
      exact
      path={[Page.INGESTION.UPDATE_CONTRACT, Page.INGESTION.UPDATE_CONTRACT_V2]}
      render={({ match }) => (
        <UpdateContract
          username={props.username}
          params={match.params}
          toggleHelp={props.toggleHelp}
          activeGroup={props.activeGroup}
          setContentType={props.setContentType}
          setNotifications={props.setNotifications}
        />
      )}
    />
    <Route
      exact
      path={[Page.INGESTION.DETAIL, Page.INGESTION.DETAIL_V2]}
      render={({ match }) => (
        <DatasetDetail
          params={match.params}
          toggleHelp={props.toggleHelp}
          activeGroup={props.activeGroup}
          setContentType={props.setContentType}
          setNotifications={props.setNotifications}
        />
      )}
    />
    <Route
      exact
      path={[Page.INGESTION.MONITORING, Page.INGESTION.MONITORING_V2]}
      render={({ match }) => (
        <Monitoring toggleHelp={props.toggleHelp} params={match.params} setContentType={props.setContentType} />
      )}
    />

    <Route path={Page.DEPRECATED_PERMISSIONS} exact render={(_) => <Redirect to={Page.MY_DATASETS} />} />
    <Route
      path={Page.DEPRECATED_PERMISSIONS_DETAILS}
      exact
      render={(route_props) => {
        return <Redirect to={Page.REQUEST_DETAILS.replace(':id', route_props.match.params.id)} />;
      }}
    />
    <Route path={Page.DEPRECATED_CATALOG} exact render={(_) => <Redirect to={Page.BROWSE_DATASETS} />} />
    <Route
      path={Page.DEPRECATED_CATALOG_DETAILS}
      exact
      render={(route_props) => {
        return <Redirect to={Page.DATASET_DETAILS.replace(':id', route_props.match.params.id)} />;
      }}
    />
    <Route
      path={Page.CREATE_WORKSPACE}
      exact
      render={(route_props) => <CreateWorkspace {...route_props} {...props} />}
    />
    <Route
      path={Page.BROWSE_WORKSPACES}
      exact
      render={(route_props) => <BrowseWorkspaces {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACE_MY_REQUESTS}
      exact
      render={(route_props) => <WSMyRequests {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACE_MY_REQUEST_DETAILS}
      exact
      render={(route_props) => <WSMyRequestsDetails {...route_props} {...props} />}
    />
    <Route
      path={Page.CURRENT_WORKSPACE_DETAIL}
      exact
      render={(route_props) => <WorkspaceDetailTab {...route_props} {...props} />}
    />
    <Route
      path={Page.UPDATE_WORKSPACE}
      exact
      render={(route_props) => <WorkspaceUpdate {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACE_DETAIL}
      exact
      render={(route_props) => <WorkspaceDetailPage {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACE_GLUE_REGISTER}
      exact
      render={(route_props) => <WorkspaceGlueRegister {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACE_REDSHIFT_REGISTER}
      exact
      render={(route_props) => <WorkspaceRedshiftRegister {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACE_ALL_PERMISSIONS}
      exact
      render={(route_props) => <AllPermissions {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACES_BROWSE_DATASETS}
      exact
      render={(route_props) => <WSBrowseDatasets {...route_props} {...props} />}
    />

    <Route
      path={Page.WORKSPACES_BROWSE_DATABASES}
      exact
      render={(route_props) => <WSBrowseDatabases {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACES_BROWSE_CATALOGS}
      exact
      render={(route_props) => <WSBrowseCatalogs {...route_props} {...props} />}
    />
    <Route path={Page.WORKSPACE} exact render={(route_props) => <CurrentWorkspace {...route_props} {...props} />} />
    <Route
      path={Page.LF_PERMISSION_DETAIL}
      exact
      render={(route_props) => <LfPermissionDetail {...route_props} {...props} />}
    />
    <Route
      path={Page.REDSHIFT_PERMISSION_DETAIL}
      exact
      render={(route_props) => <RedshiftPermissionDetail {...route_props} {...props} />}
    />
    <Route
      path={Page.LF_PERMISSION_DETAIL}
      exact
      render={(route_props) => <LfPermissionDetail {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACE_RESOURCE_LINKS}
      exact
      render={(route_props) => <WorkspaceResourceLinks {...route_props} {...props} />}
    />
    <Route
      path={Page.WORKSPACE_REDSHIFT_QUERY}
      exact
      render={(route_props) => <WorkspaceQueryEditor {...route_props} {...props} />}
    />

    <Route
      path={[Page.REDSHIFT_CATALOG_DETAILS, Page.DEPRECATED_WKS_REDSHIFT_CATALOG_DETAILS]}
      exact
      render={(route_props) => <WorkspaceRedshiftCatalogDetail {...route_props} {...props} />}
    />

    <Route
      path={[Page.REDSHIFT_DATABASE_DETAILS, Page.DEPRECATED_WKS_REDSHIFT_DATABASE_DETAILS]}
      exact
      render={(route_props) => <WorkspaceRedshiftDatabaseDetail {...route_props} {...props} />}
    />

    <Route
      path={Page.PUBLISHED_PERMISSION_DETAIL}
      exact
      render={(route_props) => <PublishedPermissionDetail {...route_props} {...props} />}
    />

    <Route path={Page.SEARCH} exact render={(route_props) => <SearchHome {...route_props} {...props} />} />
    <Route path={Page.TEMPLATES} exact render={(route_props) => <TemplatesTable {...route_props} {...props} />} />
    <Route
      path={Page.CREATE_TEMPLATE}
      exact
      render={(route_props) => <CreateTemplateComponent {...route_props} {...props} />}
    />
    <Route path={Page.TEMPLATE_DETAILS} exact render={(route_props) => <TemplateView {...route_props} {...props} />} />
    <Route path={Page.EDIT_TEMPLATE} exact render={(route_props) => <EditTemplate {...route_props} {...props} />} />

    <Route path={Page.METADATA_FORMS} exact render={(route_props) => <MetaDataForms {...route_props} {...props} />} />
    <Route
      path={Page.CREATE_METADATA_FORM}
      exact
      render={(route_props) => <CreateMetadataForm {...route_props} {...props} />}
    />
    <Route
      path={Page.EDIT_METADATA_FORM}
      exact
      render={(route_props) => <EditMetadataForm {...route_props} {...props} />}
    />
    <Route
      path={Page.VIEW_METADATA_FORM}
      exact
      render={(route_props) => <ViewMetadataForm {...route_props} {...props} />}
    />

    <Route
      path={Page.BUSINESS_GLOSSARIES}
      exact
      render={(route_props) => <MetadataBusinessGlossaries {...route_props} {...props} />}
    />
    <Route
      path={Page.CREATE_BUSINESS_GLOSSARY}
      exact
      render={(route_props) => <CreateBusinessGlossary {...route_props} {...props} />}
    />
    <Route
      path={Page.EDIT_BUSINESS_GLOSSARY}
      exact
      render={(route_props) => <EditBusinessGlossary {...route_props} {...props} />}
    />
    <Route
      path={Page.VIEW_BUSINESS_GLOSSARY}
      exact
      render={(route_props) => <ViewBusinessGlossary {...route_props} {...props} />}
    />
    <Route path='*' render={(_) => <Redirect to='/' />} />
  </Switch>
);

export default Routes;
