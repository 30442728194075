import { SignatureV4 } from '@smithy/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-js';
import { HttpRequest } from '@smithy/protocol-http';
import { getStage, LATTICE_LINEAGE_CLIENT_CONFIG } from 'src/api/config';
import { sleep } from 'src/components/lineage/lineageUtil';

let sigV4Signer;
let latticeEndpoint;
let latticeRegion;
const maxRetries = 3;
const backoffMillis = 500;

export function initLatticeWithCredentials(creds) {
  latticeRegion = LATTICE_LINEAGE_CLIENT_CONFIG[getStage()]['region'];
  latticeEndpoint = LATTICE_LINEAGE_CLIENT_CONFIG[getStage()]['endpoint'];
  sigV4Signer = new SignatureV4({
    service: 'execute-api',
    region: latticeRegion,
    credentials: creds,
    sha256: Sha256,
  });
}

// lattice URL helpers
function fetchGetLatticeLineageURL(nodeId: string) {
  let getLineageUrl = latticeEndpoint + `/api/v1/lineage?nodeId=${nodeId}&depth=1`;
  return new URL(getLineageUrl);
}

function fetchDatasetAssetVersionsURL(namespace: string, assetId: string) {
  const encodedAssetId = encodeURIComponent(assetId);
  let getAssetVersionsUrl = latticeEndpoint + `/api/v1/namespaces/${namespace}/datasets/${encodedAssetId}/versions`;
  return new URL(getAssetVersionsUrl);
}

function fetchJobRunsURL(namespace: string, assetId: string) {
  const encodedAssetId = encodeURIComponent(assetId);
  let getJobRunsUrl = latticeEndpoint + `/api/v1/namespaces/${namespace}/jobs/${encodedAssetId}/runs`;
  return new URL(getJobRunsUrl);
}

function fetchJobFacetForGivenRunURL(runId: string) {
  let getJobFacetUrl = latticeEndpoint + `/api/v1/jobs/runs/${runId}/facets?type=job`;
  return new URL(getJobFacetUrl);
}

function extractQueryParams(url: URL): Record<string, string> | undefined {
  const queryParams = {};
  for (const [key, value] of url.searchParams.entries()) {
    queryParams[key] = value;
  }
  if (Object.keys(queryParams).length === 0) return undefined;
  return queryParams;
}

// Lattice api calls
// retryInterval [500ms, 1000ms, 2000ms]
const fetchWithRetries = async (url: URL, request: any) => {
  let lastError;
  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      const response = await fetch(url, request);
      if (response.ok) {
        return response;
      }
      // Retry on specific status codes
      if (response.status >= 500 && response.status < 600) {
        lastError = new Error(`HTTP ${response.status}: ${response.statusText}`);
        if (attempt < maxRetries - 1) {
          await sleep(backoffMillis * Math.pow(2, attempt));
        }
        continue;
      }
      // Don't retry other status codes
      return response;
    } catch (error) {
      lastError = error;
      if (attempt < maxRetries - 1) {
        await sleep(backoffMillis * Math.pow(2, attempt));
      }
    }
  }
  throw lastError;
};

export async function getLatticeLineageForAsset(nodeId: string) {
  let getLineageUrl = fetchGetLatticeLineageURL(nodeId);
  const httpRequest = new HttpRequest({
    method: 'GET',
    hostname: getLineageUrl.host,
    path: getLineageUrl.pathname,
    protocol: getLineageUrl.protocol,
    headers: {
      'Content-Type': 'application/json',
      host: getLineageUrl.host,
    },
    query: extractQueryParams(getLineageUrl),
  });

  const sigV4SignedRequest = await sigV4Signer.sign(httpRequest);
  const getLineageResponse = await fetchWithRetries(getLineageUrl, sigV4SignedRequest);
  if (!getLineageResponse.ok) {
    throw new Error(`Response status: ${getLineageResponse.status}`);
  }
  return await getLineageResponse.json();
}

export async function getVersionsForDatasetAsset(namespace: string, assetId: string) {
  let getAssetVersionsUrl = fetchDatasetAssetVersionsURL(namespace, assetId);

  const httpRequest = new HttpRequest({
    method: 'GET',
    hostname: getAssetVersionsUrl.host,
    path: getAssetVersionsUrl.pathname,
    protocol: getAssetVersionsUrl.protocol,
    headers: {
      'Content-Type': 'application/json',
      host: getAssetVersionsUrl.host,
    },
    query: extractQueryParams(getAssetVersionsUrl),
  });

  const sigV4SignedRequest = await sigV4Signer.sign(httpRequest);
  const getAssetVersionsResponse = await fetchWithRetries(getAssetVersionsUrl, sigV4SignedRequest);
  if (!getAssetVersionsResponse.ok) {
    throw new Error(`Response status: ${getAssetVersionsResponse.status}`);
  }
  return await getAssetVersionsResponse.json();
}

export async function getRunsForJobAsset(namespace: string, assetId: string) {
  let getJobRunsUrl = fetchJobRunsURL(namespace, assetId);

  const httpRequest = new HttpRequest({
    method: 'GET',
    hostname: getJobRunsUrl.host,
    path: getJobRunsUrl.pathname,
    protocol: getJobRunsUrl.protocol,
    headers: {
      'Content-Type': 'application/json',
      host: getJobRunsUrl.host,
    },
    query: extractQueryParams(getJobRunsUrl),
  });

  const sigV4SignedRequest = await sigV4Signer.sign(httpRequest);

  const getJobRunsResponse = await fetchWithRetries(getJobRunsUrl, sigV4SignedRequest);

  if (!getJobRunsResponse.ok) {
    throw new Error(`Response status: ${getJobRunsResponse.status}`);
  }
  return await getJobRunsResponse.json();
}

export async function getJobFacetInfoForGivenRunId(runId: string) {
  let getJobFacetUrl = fetchJobFacetForGivenRunURL(runId);

  const httpRequest = new HttpRequest({
    method: 'GET',
    hostname: getJobFacetUrl.host,
    path: getJobFacetUrl.pathname,
    protocol: getJobFacetUrl.protocol,
    headers: {
      'Content-Type': 'application/json',
      host: getJobFacetUrl.host,
    },
    query: extractQueryParams(getJobFacetUrl),
  });

  const sigV4SignedRequest = await sigV4Signer.sign(httpRequest);

  const getJobFacetsResponse = await fetchWithRetries(getJobFacetUrl, sigV4SignedRequest);

  if (!getJobFacetsResponse.ok) {
    throw new Error(`Response status: ${getJobFacetsResponse.status}`);
  }
  return await getJobFacetsResponse.json();
}
