import {
  CustomDefaultNode,
  GlueAssetNode,
  JobNode,
  RedshiftAssetNode,
  S3AssetNode,
} from 'src/components/lineage/customLineageNodes';
import { MarkerType } from 'reactflow';
import {
  CATALOG_SYNC,
  DATASET_NODE_ID_PREFIX,
  EMR_JOB,
  GLUE_JOB,
  JOB_NODE_ID_PREFIX,
  RAM_JOB,
  REDSHIFT_JOB,
} from 'src/components/lineage/latticeLineageConstants';

enum NodeType {
  CUSTOM = 'custom',
  GLUE_ASSET = 'glueAsset',
  REDSHIFT_ASSET = 'redshiftAsset',
  S3_ASSET = 's3Asset',
  JOB_ASSET = 'jobAsset',
}

const defaultDisplayKey = 'Asset / Custom';
const defaultDisplayName = 'undefined';

/**
 * adjust these values accordingly to change the spacing between nodes
 * lineageNodeWidth === lineageNodeWidthPx
 * lineageNodeHeight === lineageNodeHeightPx
 */
export const lineageNodeWidth = 270;
export const lineageNodeHeight = 90;
export const lineageNodeWidthPx = '270px';
export const lineageNodeHeightPx = '90px';

export const nodeTypesCustom: any = {
  custom: CustomDefaultNode,
  s3Asset: S3AssetNode,
  glueAsset: GlueAssetNode,
  redshiftAsset: RedshiftAssetNode,
  jobAsset: JobNode,
};

/**
 *
 * @param nodeId = "id used in marquez, should always end with AWS arn"
 * Examples:
 * NodeId for Dataset asset: "dataset!AWSInternationalExpanRelEng!arn:aws:glue:us-east-1:350266277334:table/aws_datalake_beta_processed/o_aws_account_roles"
 * NodeId for Job Asset: "job!AWSInternationalExpanRelEng!arn:aws:redshift:us-east-1:446056755984:extSchemaJob/redshift-test/awsdw/beta_lf_test/lf_account_roles"
 *
 */
export const getNodeTypeFromNodeId = (nodeId: string) => {
  if (nodeId.startsWith(DATASET_NODE_ID_PREFIX) && nodeId.includes(':glue:')) {
    return NodeType.GLUE_ASSET;
  } else if (nodeId.startsWith(DATASET_NODE_ID_PREFIX) && nodeId.includes(':redshift:')) {
    return NodeType.REDSHIFT_ASSET;
  } else if (nodeId.startsWith(DATASET_NODE_ID_PREFIX) && nodeId.includes(':s3:')) {
    return NodeType.S3_ASSET;
  } else if (nodeId.startsWith(JOB_NODE_ID_PREFIX)) {
    return NodeType.JOB_ASSET;
  } else {
    return NodeType.CUSTOM;
  }
};

export const getImageTypeFromNodeId = (nodeId: string) => {
  if (nodeId.startsWith(DATASET_NODE_ID_PREFIX) && nodeId.includes(':glue:')) {
    return require('./lineageImages/Glue.png');
  } else if (nodeId.startsWith(DATASET_NODE_ID_PREFIX) && nodeId.includes(':redshift:')) {
    return require('./lineageImages/Redshift.png');
  } else if (nodeId.startsWith(DATASET_NODE_ID_PREFIX) && nodeId.includes(':s3:')) {
    return require('./lineageImages/S3.png');
  } else if (nodeId.startsWith(JOB_NODE_ID_PREFIX)) {
    return require('./lineageImages/Job.png');
  } else {
    return require('./lineageImages/GenericAsset.png');
  }
};

export const getDisplayKeyForNode = (nodeId: string) => {
  if (!nodeId) {
    return defaultDisplayKey;
  }
  if (nodeId.startsWith(DATASET_NODE_ID_PREFIX) && nodeId.includes(':glue:')) {
    return 'Dataset / AWS Glue';
  } else if (nodeId.startsWith(DATASET_NODE_ID_PREFIX) && nodeId.includes(':redshift:')) {
    return 'Dataset / AWS Redshift';
  } else if (nodeId.startsWith(DATASET_NODE_ID_PREFIX) && nodeId.includes(':s3:')) {
    return 'Dataset / AWS S3';
  } else if (nodeId.startsWith(JOB_NODE_ID_PREFIX)) {
    return getDisplayKeyForJobNode(nodeId);
  }
};

const getDisplayKeyForJobNode = (nodeId: string) => {
  const nodeInfo = getNodeInfoFromNodeId(nodeId);
  if (!nodeInfo || !nodeInfo.name) {
    return 'Job';
  }
  let jobArn = nodeInfo.name;
  if (jobArn.includes(EMR_JOB)) {
    return 'Job / AWS Emr';
  }
  if (jobArn.includes(RAM_JOB)) {
    return 'Job / AWS Ram';
  }
  if (jobArn.includes(GLUE_JOB)) {
    return 'Job / AWS Glue';
  }
  if (jobArn.includes(CATALOG_SYNC)) {
    return 'Job / AWS Lambda';
  }
  if (jobArn.includes(REDSHIFT_JOB)) {
    return 'Job / AWS Redshift';
  }
  return 'Job';
};

export const getDisplayNameForNode = (nodeId: string) => {
  // nodeId should always end with Arn as per OL specification
  if (!nodeId) {
    return defaultDisplayName;
  }
  // For S3 nodes (dataset!arn:aws:s3:::awsmp-datawarehouse-extracts-subledger-prod-iad!arn:aws:s3:::awsmp-datawarehouse-extracts-subledger-prod-iad//2024/10/30/O_AWSMP_SELLER_SUBLEDGERS.dat)
  if (nodeId.startsWith(DATASET_NODE_ID_PREFIX) && nodeId.includes(':s3:')) {
    const nodeInfo = getNodeInfoFromNodeId(nodeId);
    return nodeInfo.namespace;
  }
  let assetSplitList = nodeId.split('/');
  return assetSplitList[assetSplitList.length - 1] ?? nodeId;
};

/**
 *
 * @param lineageDetail= "json output of Get lineage api call"
 * setting node detail with required props and data
 */
export const setNodeDetailFromNodeInfo = (lineageDetail) => {
  let nodeId = lineageDetail?.id;
  let nodeType = lineageDetail?.type;
  let nodeData = lineageDetail?.data;
  let inputs = [];
  let outputs = [];
  let inEdges = lineageDetail?.inEdges.filter((edgeDetail) => edgeDetail && edgeDetail.origin);
  let outEdges = lineageDetail?.outEdges.filter((edgeDetail) => edgeDetail && edgeDetail.destination);
  inEdges.forEach((edgeDetail) => {
    inputs.push(edgeDetail.origin);
  });
  outEdges.forEach((edgeDetail) => {
    outputs.push(edgeDetail.destination);
  });

  return {
    id: nodeId,
    data: {
      label: nodeData.id.name,
      name: nodeData.id.name,
      namespace: nodeData.id.namespace,
      createTime: nodeData.createdAt,
      nodeType: nodeType,
      facetInfo: {},
      isBaseNode: false,
      isEndNode: false,
      inputNodeNames: inputs,
      outputNodeNames: outputs,
    },
    type: getNodeTypeFromNodeId(nodeId),
    position: { x: 0, y: 0 },
    isSelectable: true,
    isFocusable: true,
  };
};

export const setNodeDetailFromNodeId = (nodeId: string) => {
  const nodeDetail = getNodeInfoFromNodeId(nodeId);
  if (nodeDetail) {
    return {
      id: nodeId,
      data: {
        label: nodeDetail.name,
        name: nodeDetail.name,
        namespace: nodeDetail.namespace,
        facetInfo: {},
        isBaseNode: false,
        isEndNode: false,
      },
      type: getNodeTypeFromNodeId(nodeId),
      position: { x: 0, y: 0 },
      isSelectable: true,
      isFocusable: true,
    };
  }
};

/**
 *
 * @param edgeDetail = "custom edge to connect nodes on UI"
 */
export const setEdgeDetail = (edgeDetail) => {
  return {
    id: edgeDetail.origin + '#' + edgeDetail.destination,
    source: edgeDetail.origin,
    target: edgeDetail.destination,
    style: { stroke: 'grey', strokeWidth: 1 },
    animated: true,
    //type:  'smoothstep', (to display edges as steps instead of curved arrows)
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: 'grey',
      width: 20,
      height: 20,
      strokeWidth: 1,
    },
  };
};

export const sleep = (delay: number) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

export const getNodesAndEdgesFromGetLineageGraph = (lineageList: any[], nodesToInclude: Set<string>) => {
  const nodeIds = new Set<string>();
  const edgeIds = new Set<string>();

  let nodesInGraph = [];
  let edgesInGraph = [];
  // get all edges from lineage graph
  const edgesList = lineageList.flatMap((lineageDetail) => [
    ...(lineageDetail?.inEdges || []),
    ...(lineageDetail?.outEdges || []),
  ]);
  // add edges only for nodes to be included
  edgesList.forEach((edgeDetail) => {
    const isNodesIncluded = nodesToInclude.has(edgeDetail?.origin) && nodesToInclude.has(edgeDetail?.destination);
    const isDatasetDestination =
      edgeDetail?.destination?.startsWith(DATASET_NODE_ID_PREFIX) &&
      (nodesToInclude.has(edgeDetail?.origin) || nodesToInclude.has(edgeDetail?.destination));
    const edgeId = `${edgeDetail?.origin}#${edgeDetail?.destination}`;
    const isNewEdge = !edgeIds.has(edgeId);
    if ((isNodesIncluded || isDatasetDestination) && isNewEdge) {
      nodeIds.add(edgeDetail?.origin);
      nodeIds.add(edgeDetail?.destination);
      edgesInGraph.push(setEdgeDetail(edgeDetail));
      edgeIds.add(edgeDetail.origin + '#' + edgeDetail.destination);
    }
  });
  // add only nodes to be included
  lineageList.forEach((lineageDetail) => {
    let nodeId = lineageDetail?.id;
    if (nodeIds.has(nodeId)) {
      nodesInGraph.push(setNodeDetailFromNodeInfo(lineageDetail));
      nodeIds.delete(nodeId);
    }
  });
  if (nodeIds.size) {
    nodeIds.forEach((nodeId) => {
      nodesInGraph.push(setNodeDetailFromNodeId(nodeId));
    });
  }
  return { nodesInGraph, edgesInGraph };
};

export const getNodeIdsToBeIncludedInGraph = (lineageList: any[], activeNodeIds: string[], currentNodeId: string) => {
  let nodesToBeIncluded = new Set<string>([...activeNodeIds]);
  const targetNode = lineageList.find((item) => item.id === currentNodeId);
  if (!targetNode) return nodesToBeIncluded;

  let allEdges = [...(targetNode?.inEdges ?? []), ...(targetNode?.outEdges ?? [])].filter(Boolean);
  allEdges.forEach((edgeDetail) => {
    if (edgeDetail) {
      nodesToBeIncluded.add(edgeDetail.origin);
      nodesToBeIncluded.add(edgeDetail.destination);
    }
  });
  return nodesToBeIncluded;
};

export function getNodeInfoFromNodeId(nodeId: string): NodeIdInfo {
  try {
    let nodeSplitInfo = nodeId.split('!');
    return {
      type: nodeSplitInfo[0],
      namespace: nodeSplitInfo[1],
      name: nodeSplitInfo[2],
    };
  } catch (err) {
    console.log(`Failed to get node info from nodeId: ${nodeId}. Reason: ${err}`);
    return undefined;
  }
}

export interface NodeIdInfo {
  type: string;
  namespace: string;
  name: string;
}
