import * as React from 'react';
import { useEffect, useState } from 'react';
import 'reactflow/dist/style.css';
import './lineageStyle.css';

import { Alert, Badge, SpaceBetween } from '@amzn/awsui-components-react-v3';
import {
  getAWSResourceArnFromHybridCatalogId,
  getHybridCatalogResourceArnFromHybridCatalogId,
} from 'src/components/utils/arnUtil';
import {
  getAllMetaDataFieldsForForm,
  getAllMetaDataValuesForFormAndResource,
  listAllMetadataFormEntitiesAssociatedForResource,
} from 'src/commons/commonHCApiCalls';
import { ACCOUNT_DETAIL_GLOBAL_FORM_NAME, COST_CENTER_NAME_METADATA_FIELD_KEY } from 'src/commons/constants';
import { removeSpecialCharactersFromValue } from 'src/components/utils/regexUtil';
import {
  getJobFacetInfoForGivenRunId,
  getLatticeLineageForAsset,
  getRunsForJobAsset,
  getVersionsForDatasetAsset,
} from 'src/api/latticelineage';
import {
  Background,
  BackgroundVariant,
  ControlButton,
  Controls,
  Panel,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import {
  getNodeIdsToBeIncludedInGraph,
  getNodesAndEdgesFromGetLineageGraph,
  nodeTypesCustom,
} from 'src/components/lineage/lineageUtil';
import { getLayoutedElements } from 'src/components/lineage/dagreHelper';
import { FaRedo } from 'react-icons/fa';
import { selectedLineageNodeComponent } from 'src/components/lineage/lineageComponents';
import { FACETS_KEY } from 'src/components/lineage/latticeLineageConstants';
import { Spinner } from '@amzn/awsui-components-react';

export interface LineageDetailProps {
  hcDataset: any;
}

export const LineageDetail = (props: LineageDetailProps) => {
  const [loadingLineage, setLoadingLineage] = useState(false);
  const [loadingSelectedNode, setLoadingSelectedNode] = useState(true);
  const [emptyLineage, setEmptyLineage] = useState(false);
  const [lineageNodes, setLineageNodes] = useNodesState([]);
  const [lineageEdges, setLineageEdges] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(undefined);
  const [baseNodeId, setBaseNodeId] = useState(undefined);
  const [activeNodeIds, setActiveNodeIds] = useState([]);

  // reset all states
  const resetStates = () => {
    setLineageEdges([]);
    setLineageNodes([]);
    setActiveNodeIds([]);
    setBaseNodeId(undefined);
    setSelectedNode(undefined);
    setLoadingLineage(true);
    setLoadingSelectedNode(true);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    if (props.hcDataset?.Id) {
      // reset
      resetStates();
      const hybridCatalogDatasetArn = getHybridCatalogResourceArnFromHybridCatalogId(props?.hcDataset?.Id);
      const awsDatasetArn = getAWSResourceArnFromHybridCatalogId(props?.hcDataset?.Id);
      let accountDetailsFormId = undefined;
      let costCenterFieldId = undefined;
      let filteredCostCenterValue = undefined;
      if (hybridCatalogDatasetArn) {
        accountDetailsFormId = await getAccountDetailsFormIdAttachedToHCResource(hybridCatalogDatasetArn);
      }
      if (accountDetailsFormId) {
        costCenterFieldId = await getCostCenterFieldIdForForm(accountDetailsFormId);
      }
      if (costCenterFieldId) {
        filteredCostCenterValue = await getCostCenterValueFromGivenFormFieldAndResource(
          accountDetailsFormId,
          costCenterFieldId,
          hybridCatalogDatasetArn,
        );
      }
      if (filteredCostCenterValue) {
        let nodeId = 'dataset' + '!' + filteredCostCenterValue + '!' + awsDatasetArn;
        setBaseNodeId(nodeId);
      }
    }
  };

  useEffect(() => {
    if (baseNodeId) {
      setActiveNodeIds([...activeNodeIds, baseNodeId]);
    }
  }, [baseNodeId]);

  useEffect(() => {
    if (selectedNode) {
      setActiveNodeIds([...activeNodeIds, selectedNode.id]);
    }
  }, [selectedNode]);

  useEffect(() => {
    if (activeNodeIds.length) {
      if (selectedNode) {
        fetchLineageForAsset(selectedNode.id, false).then(() => {
          setLoadingSelectedNode(false);
        });
      } else {
        fetchLineageForAsset(baseNodeId, true);
      }
    }
  }, [activeNodeIds.length || selectedNode]);

  const fetchFacetInfoForNodeId = async (nodeId) => {
    let facetInfo = undefined;
    if (nodeId) {
      try {
        let nodeDetail = nodeId.split('!');
        if (nodeDetail[0] === 'dataset') {
          facetInfo = await getMetadataInfoForDatasetNodeType(nodeDetail[1], nodeDetail[2]);
        }

        if (nodeDetail[0] === 'job') {
          facetInfo = await getMetadataInfoForJobNodeType(nodeDetail[1], nodeDetail[2]);
        }
      } catch (err) {
        console.log(`Failed to fetch facetInfo for node:${nodeId}. Reason:${err}`);
      }
    }
    return facetInfo;
  };

  const getAccountDetailsFormIdAttachedToHCResource = async (hcDatasetArn) => {
    let allFormsAttachedToResource = [];
    try {
      allFormsAttachedToResource = await listAllMetadataFormEntitiesAssociatedForResource(hcDatasetArn);
      // fetch the required form
      const requiredFormAsList = allFormsAttachedToResource.filter(
        (form) => form?.Name === ACCOUNT_DETAIL_GLOBAL_FORM_NAME,
      );
      if (requiredFormAsList.length == 0) {
        setEmptyLineage(true);
        setLoadingLineage(false);
        return undefined;
      } else {
        return requiredFormAsList[0].MetadataFormId;
      }
    } catch (err) {
      console.log(`Failed to fetch metadata forms for resource: ${hcDatasetArn}`);
      setEmptyLineage(true);
      setLoadingLineage(false);
    }
  };

  const getCostCenterFieldIdForForm = async (formId) => {
    try {
      let allFieldsForForm = await getAllMetaDataFieldsForForm(formId);
      // fetch the required field
      const requiredFieldAsList = allFieldsForForm.filter(
        (field) => field?.Name === COST_CENTER_NAME_METADATA_FIELD_KEY,
      );
      if (requiredFieldAsList.length == 0) {
        setEmptyLineage(true);
        setLoadingLineage(false);
        return undefined;
      } else {
        return requiredFieldAsList[0].MetadataFieldId;
      }
    } catch (err) {
      console.log(`Failed to fetch metadata fields for form: ${formId}`);
      setEmptyLineage(true);
      setLoadingLineage(false);
    }
  };

  const getCostCenterValueFromGivenFormFieldAndResource = async (formId, fieldId, hcDatasetArn) => {
    try {
      let allValuesForFormAndResource = await getAllMetaDataValuesForFormAndResource(formId, hcDatasetArn);
      // fetch the required value
      const requiredValueAsList = allValuesForFormAndResource.filter((value) => value?.Id === fieldId);
      if (requiredValueAsList.length === 0) {
        setEmptyLineage(true);
        setLoadingLineage(false);
        return undefined;
      } else {
        return removeSpecialCharactersFromValue(requiredValueAsList[0].Value?.StringValue);
      }
    } catch (err) {
      console.log(
        `Failed to fetch metadata value for form:${formId}, field: ${fieldId}, resource:${hcDatasetArn}. Reason: ${err}`,
      );
      setEmptyLineage(true);
      setLoadingLineage(false);
    }
  };

  const fetchLineageForAsset = async (nodeId: string, resetLineage: boolean) => {
    try {
      const getLineageResponseData = await getLatticeLineageForAsset(nodeId);
      const facetInfo = await fetchFacetInfoForNodeId(nodeId);
      await processAndSetLineage(getLineageResponseData.graph, resetLineage, nodeId, facetInfo);
    } catch (err) {
      console.log(`Failed to fetch lineage value for nodeId:${nodeId}. Reason:${err}`);
      setEmptyLineage(true);
    } finally {
      setLoadingLineage(false);
    }
  };

  const processAndSetLineage = async (
    lineageList: any[],
    resetLineage: boolean,
    currentNodeId: string,
    selectedNodeMetadata: any,
  ) => {
    let nodesToBeIncludedInGraph = getNodeIdsToBeIncludedInGraph(lineageList, activeNodeIds, currentNodeId);
    let nodesAndEdgesInGraph = getNodesAndEdgesFromGetLineageGraph(lineageList, nodesToBeIncludedInGraph);
    const nodesInGraph = nodesAndEdgesInGraph.nodesInGraph;
    const edgesInGraph = nodesAndEdgesInGraph.edgesInGraph;
    let layoutedNodes: any[];
    let layoutedEdges: any[];
    let currentNode = nodesInGraph.find((node) => node && node.id === currentNodeId);
    if (resetLineage) {
      const { positionedNodes, positionedEdges } = getLayoutedElements(nodesInGraph, edgesInGraph);
      layoutedNodes = positionedNodes;
      layoutedEdges = positionedEdges;
      // set base node on reset
      let baseNode = layoutedNodes.find((node) => node && node.id === baseNodeId);
      if (baseNode) {
        baseNode.data.isBaseNode = true;
      }
    } else {
      // including previous nodes and edges when state update
      let existingNodeIdList = lineageNodes.filter((node) => node).map((node) => node.id);
      let newNodesInGraph = nodesInGraph.filter((node) => !existingNodeIdList.includes(node.id));
      let existingEdgeIdList = lineageEdges.filter((edge) => edge).map((edge) => edge.id);
      let newEdgesInGraph = edgesInGraph.filter((edge) => !existingEdgeIdList.includes(edge.id));
      const { positionedNodes, positionedEdges } = getLayoutedElements(
        [...lineageNodes, ...newNodesInGraph],
        [...lineageEdges, ...newEdgesInGraph],
      );
      layoutedNodes = positionedNodes;
      layoutedEdges = positionedEdges;
    }

    // set metadata from initial current node
    if (currentNode) {
      let requiredNode = layoutedNodes.find((node) => node && node.id === currentNode.id);
      if (requiredNode) {
        requiredNode.data.inputNodeNames = currentNode.data?.inputNodeNames;
        requiredNode.data.outputNodeNames = currentNode.data?.outputNodeNames;
      }
    }
    // set metadata for selected node
    if (selectedNode) {
      let requiredNode = layoutedNodes.find((node) => node && node.id === selectedNode.id);
      if (requiredNode) {
        requiredNode.selected = true;
        requiredNode.data.facetInfo = selectedNodeMetadata;
      }
    }
    setLineageNodes(layoutedNodes);
    setLineageEdges(layoutedEdges);
  };

  const getMetadataInfoForDatasetNodeType = async (namespace: string, assetName: string) => {
    let facetInfo = undefined;
    try {
      let getVersionsResponseData = await getVersionsForDatasetAsset(namespace, assetName);
      // add facet information to the selected node
      let latestVersion = getVersionsResponseData?.versions?.length
        ? getVersionsResponseData?.versions?.reduce((latest, current) =>
            new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest,
          )
        : undefined;
      if (latestVersion && latestVersion[FACETS_KEY] && Object.keys(latestVersion[FACETS_KEY]).length > 0) {
        facetInfo = latestVersion[FACETS_KEY];
      }
    } catch (e) {
      console.log(`Failed to add additional metadata to asset:${assetName}`);
    }
    return facetInfo;
  };

  const getMetadataInfoForJobNodeType = async (namespace: string, assetName: string) => {
    let facetInfo = undefined;
    try {
      let getJobRunsResponseData = await getRunsForJobAsset(namespace, assetName);
      let latestRun = getJobRunsResponseData?.runs?.length
        ? getJobRunsResponseData?.runs?.reduce((latest, current) =>
            new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest,
          )
        : undefined;
      if (latestRun) {
        let getJobFacetData = await getJobFacetInfoForGivenRunId(latestRun.id);
        if (getJobFacetData && getJobFacetData[FACETS_KEY] && Object.keys(getJobFacetData[FACETS_KEY]).length > 0) {
          facetInfo = getJobFacetData[FACETS_KEY];
        }
      }
    } catch (e) {
      console.log(`Failed to add additional metadata to asset:${assetName}. Reason:${e}`);
    }
    return facetInfo;
  };

  const handleOnNodeDragProcess = (event, node) => {
    setLineageNodes((lineageNodes) =>
      lineageNodes.map((n) => (n.id === node.id ? { ...n, position: node.position } : n)),
    );
    event.stopPropagation();
  };

  const handleNodeClickProcess = async (event, node) => {
    node.selected = true;
    setLoadingSelectedNode(true);
    setSelectedNode(node);
    event.stopPropagation();
  };

  const handleOnPaneClickProcess = () => {
    setSelectedNode(undefined);
  };

  const closePanel = () => {
    setSelectedNode(undefined);
  };

  if (loadingLineage) {
    return <Spinner size='large' />;
  } else if (emptyLineage) {
    return (
      <Alert data-testid='lineage-alert' header='No Lineage Found'>
        Lineage information is not available for this dataset.
      </Alert>
    );
  } else {
    return (
      <div className='lineage-container'>
        <div className='lineage-react-flow-wrapper'>
          <ReactFlow
            nodes={lineageNodes}
            edges={lineageEdges}
            fitView
            nodeTypes={nodeTypesCustom}
            selectNodesOnDrag={false}
            nodesDraggable={true}
            elementsSelectable={true}
            nodesFocusable={true}
            onNodeClick={handleNodeClickProcess}
            onNodeDragStop={handleOnNodeDragProcess}
            onPaneClick={handleOnPaneClickProcess}
            proOptions={{ hideAttribution: true }}
          >
            <Background color='#ccc' variant={BackgroundVariant.Dots} size={2} />
            <Controls position='top-right'>
              <ControlButton onClick={handleRefresh}>
                <FaRedo />
              </ControlButton>
            </Controls>
            <Panel position='top-left'>
              <SpaceBetween direction='vertical' size='xs'>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Badge color='green'>-</Badge>
                  <span>Base node</span>
                </div>
              </SpaceBetween>
            </Panel>
          </ReactFlow>
        </div>
        {selectedNode && selectedLineageNodeComponent(selectedNode, closePanel, loadingSelectedNode)}
      </div>
    );
  }
};
