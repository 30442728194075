import * as dagre from 'dagre-d3-es';
import * as d3 from 'd3';
import { lineageNodeHeight, lineageNodeWidth } from 'src/components/lineage/lineageUtil';

/**
 * Ref: https://codesandbox.io/p/sandbox/dagre-d3-es-tree-9ywg9
 * This library will auto position the nodes based on the incoming and outgoing edges
 */

export const getLayoutedElements = (nodes, edges) => {
  const direction = 'LR'; // Horizontal layout of lineage
  // First, clean up existing graph
  const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  d3.select('svg').selectAll('*').remove();

  dagreGraph.setGraph({
    rankdir: direction,
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: lineageNodeWidth, height: lineageNodeHeight });
  });
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  let svg = d3.select('svg');
  let inner = svg.select('g');
  let render = dagre.render();
  render(inner, dagreGraph);

  const newNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const newNode = {
      ...node,
      targetPosition: 'left',
      sourcePosition: 'right',
      // auto set position
      position: {
        x: nodeWithPosition.x,
        y: nodeWithPosition.y,
      },
      selected: false,
    };
    return newNode;
  });
  return { positionedNodes: newNodes, positionedEdges: edges };
};
