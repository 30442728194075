import {
  Box,
  Button,
  Container,
  Header,
  Icon,
  KeyValuePairs,
  Link,
  SpaceBetween,
  Spinner,
  Table,
  Tabs,
  Textarea,
} from '@amzn/awsui-components-react-v3';
import {
  getDisplayKeyForNode,
  getDisplayNameForNode,
  getImageTypeFromNodeId,
} from 'src/components/lineage/lineageUtil';
import * as React from 'react';
import './lineageStyle.css';
import { getHybridCatalogIdFromAWSResourceArn } from 'src/components/utils/arnUtil';
import { createDatasetDetailLink } from 'src/routes';
import { ExpandableSection } from '@amzn/awsui-components-react-v3/polaris';
import {
  DATASET_NODE_ID_PREFIX,
  NAME_FACET_KEY,
  OWNERS_FACET_KEY,
  OWNERSHIP_FACET_KEY,
  QUERY_FACET_KEY,
  SQL_FACET_KEY,
  TYPE_FACET_KEY,
} from 'src/components/lineage/latticeLineageConstants';

/**
 *
 * @param selectedNode = member of type CustomLineageNode created
 * @param closePanel = helper function from lineageDetail.tsx
 * @param loading = boolean to indicate the selected node details are being loaded
 */
export const selectedLineageNodeComponent = (selectedNode, closePanel, loading: boolean) => {
  let hcResourceId = getHybridCatalogIdFromAWSResourceArn(selectedNode?.data?.name);
  return (
    <div className='lineage-selected-node'>
      <Container
        data-testid='selected-node-container'
        fitHeight
        header={
          <Header
            variant='h2'
            actions={<Button iconAlign='right' variant='icon' iconName='close' onClick={closePanel} />}
            counter={null}
          >
            <SpaceBetween size='xs' direction='horizontal'>
              <Icon url={getImageTypeFromNodeId(selectedNode?.id)} size='big' />
              {hcResourceId ? (
                <Link external={true} href={createDatasetDetailLink(hcResourceId)}>
                  {getDisplayNameForNode(selectedNode?.id)}
                </Link>
              ) : (
                getDisplayNameForNode(selectedNode?.id)
              )}
            </SpaceBetween>
          </Header>
        }
      >
        {loading ? <Spinner size='big' /> : getSelectedNodeDisplayComponentBasedOnNodeType(selectedNode)}
      </Container>
    </div>
  );
};

const getSelectedNodeDisplayComponentBasedOnNodeType = (selectedNode) => {
  if (!selectedNode) {
    return <p>No info available</p>;
  }
  let schemaItems = [];
  let inputItems = [];
  let outputItems = [];
  if (selectedNode && selectedNode?.data?.inputNodeNames) {
    selectedNode.data.inputNodeNames.forEach((inputNodeName) => {
      let splitNodeId = inputNodeName.split('!');
      inputItems.push({
        name: splitNodeId.at(-1) || inputNodeName,
        type: getDisplayKeyForNode(inputNodeName),
      });
    });
  }
  if (selectedNode && selectedNode?.data?.outputNodeNames) {
    selectedNode.data.outputNodeNames.forEach((outputNodeName) => {
      let splitNodeId = outputNodeName.split('!');
      outputItems.push({
        name: splitNodeId.at(-1) || outputNodeName,
        type: getDisplayKeyForNode(outputNodeName),
      });
    });
  }

  if (selectedNode && selectedNode?.id.startsWith(DATASET_NODE_ID_PREFIX)) {
    // this is the path format to fetch column info from json output
    selectedNode?.data?.facetInfo?.schema?.fields.forEach((field) => {
      schemaItems.push({
        name: field?.name,
        type: field?.type,
        description: field?.description,
      });
    });
    let tabs = [];
    tabs.push(getAssetInfoTabDetail(selectedNode));
    // tabs.push(getSchemaInfoTabDetail(schemaItems)); // not including schema detail for selected node
    tabs.push(getInputsTabDetail(inputItems));
    tabs.push(getOutputsTabDetail(outputItems));

    return <Tabs data-testid='dataset-node-detail-tab' tabs={tabs} />;
  } else {
    // job node
    let tabs = [];
    tabs.push(getAssetInfoTabDetail(selectedNode));
    tabs.push(getInputsTabDetail(inputItems));
    tabs.push(getOutputsTabDetail(outputItems));
    return <Tabs data-testid='job-node-detail-tab' tabs={tabs} />;
  }
};

const getAssetInfoTabDetail = (selectedNode) => {
  return {
    label: 'Details',
    id: 'details',
    content: (
      <>
        {selectedNode && assetInfoBlockInSelectedNodeDisplayComponent(selectedNode)}
        <>
          {selectedNode?.data?.facetInfo && facetInfoBlockInSelectedNodeDisplayComponent(selectedNode.data.facetInfo)}
        </>
      </>
    ),
  };
};

const getInputsTabDetail = (inputItems: any[]) => {
  return {
    label: 'Inputs',
    id: 'inputs',
    content: (
      <>
        <Table
          columnDefinitions={[
            {
              id: 'type',
              header: 'Type',
              cell: (item) => item.type || '-',
              sortingField: 'type',
              isRowHeader: true,
            },
            {
              id: 'name',
              header: 'Name',
              cell: (item) => item.name || '-',
              sortingField: 'name',
            },
          ]}
          items={inputItems}
          loadingText='Loading inputs'
          empty={
            <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
              <b>No inputs </b>
            </Box>
          }
          header={<Header> Inputs </Header>}
        />
      </>
    ),
  };
};

const getOutputsTabDetail = (outputItems: any[]) => {
  return {
    label: 'Outputs',
    id: 'outputs',
    content: (
      <>
        <Table
          columnDefinitions={[
            {
              id: 'type',
              header: 'Type',
              cell: (item) => item.type || '-',
              sortingField: 'type',
              isRowHeader: true,
            },
            {
              id: 'name',
              header: 'Name',
              cell: (item) => item.name || '-',
              sortingField: 'name',
            },
          ]}
          items={outputItems}
          loadingText='Loading outputs'
          empty={
            <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
              <b>No outputs </b>
            </Box>
          }
          header={<Header> Outputs </Header>}
        />
      </>
    ),
  };
};

const assetInfoBlockInSelectedNodeDisplayComponent = (selectedNode) => {
  return (
    <KeyValuePairs
      columns={2}
      items={[
        {
          label: 'Asset ID',
          value: selectedNode?.id,
        },
        {
          label: 'Asset name',
          value: selectedNode?.data?.label,
        },
        {
          label: 'Create time',
          value: selectedNode?.data?.createTime,
        },
      ]}
    />
  );
};

/**
 *
 * https://quip-amazon.com/XolVAkEwBcW5/OpenLineage-Specification-DRAFT#temp:C:BIJa7024a98f99f4832b069a1ec6
 * @param facetInfo = "key and values of allowed facets are in above doc"
 */
const facetInfoBlockInSelectedNodeDisplayComponent = (facetInfo) => {
  // currently fetching sql and owner facets, more can be added as available
  if (!facetInfo) {
    return;
  }
  let sqlFacet = facetInfo[SQL_FACET_KEY];
  let ownerDetailsAsList = facetInfo[OWNERSHIP_FACET_KEY]?.[OWNERS_FACET_KEY];
  let ownerDetailKeyValuePairs = [];
  ownerDetailsAsList?.forEach((ownerDetail) => {
    if (TYPE_FACET_KEY in ownerDetail && NAME_FACET_KEY in ownerDetail) {
      ownerDetailKeyValuePairs.push({
        label: ownerDetail[TYPE_FACET_KEY],
        value: ownerDetail[NAME_FACET_KEY],
      });
    }
  });

  return (
    <>
      {ownerDetailsAsList && (
        <>
          <br />
          <ExpandableSection headerText='Ownership details'>
            <KeyValuePairs columns={2} items={ownerDetailKeyValuePairs} />
          </ExpandableSection>
        </>
      )}
      {sqlFacet && QUERY_FACET_KEY in sqlFacet && (
        <>
          <br />
          <ExpandableSection headerText='Sql query'>
            <Textarea value={sqlFacet[QUERY_FACET_KEY]} readOnly disabled />
          </ExpandableSection>
        </>
      )}
    </>
  );
};

// const getSchemaInfoTabDetail = (schemaItems: any[]) => {
//   return {
//     label: 'Schema info',
//     id: 'schemaInfo',
//     content: (
//       <>
//         <Table
//           columnDefinitions={[
//             {
//               id: 'columnName',
//               header: 'Column name',
//               cell: (item) => item.name || '-',
//               sortingField: 'columnName',
//               isRowHeader: true,
//             },
//             {
//               id: 'columnType',
//               header: 'Type',
//               cell: (item) => item.type || '-',
//               sortingField: 'columnType',
//             },
//             {
//               id: 'description',
//               header: 'Description',
//               cell: (item) => item.description || '-',
//             },
//           ]}
//           items={schemaItems}
//           loadingText='Loading schema'
//           empty={
//             <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
//               <b>No schema information</b>
//             </Box>
//           }
//           header={<Header> Schema </Header>}
//         />
//       </>
//     ),
//   };
// }
