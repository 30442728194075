import React, { useEffect, useState } from 'react';
import { ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';

import { getMetricMetadataById } from 'src/api/metricstore';

import { labeledTextFromList, ErrorAlert } from 'src/components/resourcesmanager/components';
import { Redirect } from 'react-router-dom';
import { dateString } from 'src/components/metricstore/helpers';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface MetricDefinitionProps {
  setContentType: any;
  match: any;
}

export const MetricDefinition = (props: MetricDefinitionProps) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [metricMetadata, setMetricMetadata] = useState(null);
  const [redirect] = useState(undefined);
  const [redirectParams] = useState({});

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  /**
   * Asynchronously fetches the metric definition from the metricstore API and updates the state.
   */
  const handleRefresh = async () => {
    setLoading(true);

    try {
      const metricMetadataResponse = await getMetricMetadataById({
        id: props.match.params.id,
      });
      setMetricMetadata(metricMetadataResponse);
    } catch (err) {
      console.log(err);
      setError('Error loading metric metadata');
    }
    setLoading(false);
  };

  if (redirect) {
    return <Redirect push to={{ pathname: redirect, state: redirectParams }} />;
  }

  return (
    <>
      {ErrorAlert(error)}

      <SpaceBetween size='l'>
        <Container header={<Header variant='h2'> {metricMetadata?.name} </Header>}>
          <ColumnLayout columns={2} borders='horizontal'>
            {labeledTextFromList(
              [
                {
                  label: 'Id',
                  value: metricMetadata?.id,
                  copiable: true,
                },
                {
                  label: 'Name',
                  value: metricMetadata?.name,
                  copiable: true,
                },
                {
                  label: 'Description',
                  value: metricMetadata?.description,
                  copiable: false,
                },
                {
                  label: 'Status',
                  value: metricMetadata?.isArchived ? 'Inactive' : 'Active',
                  copiable: false,
                },
                {
                  label: 'Owner team',
                  value: metricMetadata?.ownerTeam,
                  copiable: false,
                },
                {
                  label: 'Owner email',
                  value: metricMetadata?.ownerEmail,
                  copiable: false,
                },
                {
                  label: 'Source queries',
                  value: JSON.stringify(metricMetadata?.sourceQueries),
                  copiable: false,
                },
                {
                  label: 'Owner CTI',
                  value:
                    metricMetadata?.ownerCTI?.category +
                    '/' +
                    metricMetadata?.ownerCTI?.type +
                    '/' +
                    metricMetadata?.ownerCTI?.item,
                  copiable: false,
                },
                {
                  label: 'Tags',
                  value: metricMetadata?.tags?.toString(),
                  copiable: false,
                },
                {
                  label: 'Last refreshed at',
                  value: dateString(metricMetadata?.lastRefreshedAt),
                  copiable: false,
                },
                {
                  label: 'Created by',
                  value: metricMetadata?.createdBy,
                  copiable: true,
                },
                {
                  label: 'Created at',
                  value: dateString(metricMetadata?.createdAt),
                },
                {
                  label: 'Updated by',
                  value: metricMetadata?.updatedBy,
                  copiable: true,
                },
                {
                  label: 'Updated at',
                  value: dateString(metricMetadata?.updatedAt),
                },
              ],
              loading,
            )}
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    </>
  );
};
