import { Box, Button, Header, Input, Select, SpaceBetween, Table } from '@amzn/awsui-components-react-v3';
import React, { useEffect, useState } from 'react';
import {
  COMMENT_TOO_LONG_ERROR,
  CTI,
  DEFAULT_CTI,
  DEFAULT_EMAIL,
  DEFAULT_LINK,
  DEFAULT_ONCALL_LINK,
  DEFAULT_SLACK,
  DEFAULT_WIKI,
  EMAIL,
  LINK,
  ONCALL,
  READABLE_CTI,
  READABLE_EMAIL,
  READABLE_LINK,
  READABLE_ONCALL_LINK,
  READABLE_SLACK,
  READABLE_WIKI,
  SLACK,
  validateCti,
  validateEmail,
  validateGenericLink,
  validateOncallLink,
  validateSlack,
  validateWikiLink,
  WIKI,
} from 'src/components/dataadvisory/constants';
import { AdvisoryContactInfoList } from 'aws-sdk/clients/awsdlomni';

export interface ContactInfoEditorProps {
  contactInfo: AdvisoryContactInfoList;
  setContactInfo?: any;
  setErrorMessage?: any;
  readonly: boolean;
}

export const ContactInfoEditor = (props: ContactInfoEditorProps) => {
  const [currentEditValue, setCurrentEditValue] = useState(undefined);

  const CONTACT_TYPE_OPTIONS = [
    { label: READABLE_EMAIL, value: EMAIL },
    { label: READABLE_SLACK, value: SLACK },
    { label: READABLE_WIKI, value: WIKI },
    { label: READABLE_CTI, value: CTI },
    { label: READABLE_ONCALL_LINK, value: ONCALL },
    { label: READABLE_LINK, value: LINK },
  ];

  const defaultValueForType = (type) => {
    if (type == EMAIL) return DEFAULT_EMAIL;
    if (type == SLACK) return DEFAULT_SLACK;
    if (type == WIKI) return DEFAULT_WIKI;
    if (type == CTI) return DEFAULT_CTI;
    if (type == ONCALL) return DEFAULT_ONCALL_LINK;
    if (type == LINK) return DEFAULT_LINK;
  };

  const readableType = (type) => {
    if (type == EMAIL) return READABLE_EMAIL;
    if (type == SLACK) return READABLE_SLACK;
    if (type == WIKI) return READABLE_WIKI;
    if (type == CTI) return READABLE_CTI;
    if (type == ONCALL) return READABLE_ONCALL_LINK;
    if (type == LINK) return READABLE_LINK;
  };

  const addItem = () => {
    let item = {
      type: LINK,
      value: defaultValueForType(LINK),
      comment: '',
    };
    let newItems = [];
    newItems.push(...props.contactInfo);
    newItems.push(item);
    props.setContactInfo(newItems);
  };

  const validateContactInfo = (contactInfo) => {
    const type = contactInfo.type;
    const value = contactInfo.value;
    if (type == EMAIL) {
      return validateEmail(value);
    } else if (type == SLACK) {
      return validateSlack(value);
    } else if (type == WIKI) {
      return validateWikiLink(value);
    } else if (type == CTI) {
      return validateCti(value);
    } else if (type == ONCALL) {
      return validateOncallLink(value);
    } else if (type == LINK) {
      return validateGenericLink(value);
    }
    return false;
  };

  const validateContactInfos = () => {
    if (!props.setErrorMessage) return;

    let invalidValues = [];
    props.contactInfo.forEach((item) => {
      if (!validateContactInfo(item)) {
        invalidValues.push(item.value);
      }
    });

    const aCommentIsTooLong = props.contactInfo.some(contact => contact.comment?.length > 256);
    if (aCommentIsTooLong) {
      props.setErrorMessage(COMMENT_TOO_LONG_ERROR);
    } else if (invalidValues.length > 0) {
      props.setErrorMessage('The following values are invalid: ' + invalidValues.toString().replaceAll(',', ', '));
    } else {
      props.setErrorMessage(undefined);
    }
  };

  useEffect(() => {
    validateContactInfos();
  }, [props.contactInfo]);

  return (
    <>
      <Table
        items={props.contactInfo}
        ariaLabels={{
          activateEditLabel: (column, item) => `Edit ${item.name} ${column.header}`,
          cancelEditLabel: (column) => `Cancel editing ${column.header}`,
          submitEditLabel: (column) => `Submit editing ${column.header}`,
        }}
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign={'center'} color={'inherit'}>
            <SpaceBetween size={'m'}>
              <b>No contact methods</b>
              <p>Add methods above.</p>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            counter={'(' + props.contactInfo.length + ')'}
            actions={
              props.readonly ? (
                []
              ) : (
                <Button variant={'normal'} onClick={() => addItem()}>
                  Add contact info
                </Button>
              )
            }
          >
            Contact info
          </Header>
        }
        columnDefinitions={[
          {
            id: 'type',
            header: 'Type',
            cell: (item) => readableType(item.type),
            minWidth: 35,
            editConfig: props.readonly
              ? undefined
              : {
                  ariaLabel: 'Contact type',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Type error',
                  editingCell: (item, { currentValue, setValue }) => {
                    const value = currentValue ?? item.type;
                    return (
                      <Select
                        autoFocus={true}
                        expandToViewport={true}
                        selectedOption={CONTACT_TYPE_OPTIONS.find((option) => option.value === value) ?? null}
                        onChange={(event) => {
                          setValue(event.detail.selectedOption.value ?? item.type);
                          setCurrentEditValue(event.detail.selectedOption.value ?? item.type);
                        }}
                        options={CONTACT_TYPE_OPTIONS}
                      />
                    );
                  },
                },
          },
          {
            id: 'value',
            header: 'Value',
            cell: (item) => item.value,
            minWidth: 125,
            editConfig: props.readonly
              ? undefined
              : {
                  ariaLabel: 'Contact value',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Value error',
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={currentValue ?? item.value}
                        onChange={(event) => {
                          const value = event.detail.value ?? item.value;
                          setValue(value);
                          setCurrentEditValue(value);
                        }}
                        placeholder={defaultValueForType(item.type)}
                      />
                    );
                  },
                },
          },
          {
            id: 'comment',
            header: 'Comment',
            cell: (item) => item.comment,
            minWidth: 175,
            editConfig: props.readonly
              ? undefined
              : {
                  ariaLabel: 'Contact method comment',
                  editIconAriaLabel: 'editable',
                  errorIconAriaLabel: 'Comment error',
                  editingCell: (item, { currentValue, setValue }) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={currentValue ?? item.comment}
                        onChange={(event) => {
                          const value = event.detail.value ?? item.comment;
                          setValue(value);
                          setCurrentEditValue(value);
                        }}
                        placeholder={'Optional comment for this item.'}
                      />
                    );
                  },
                },
          },
          {
            id: 'actions',
            header: '',
            cell: (item) => (
              <Button
                variant={'icon'}
                iconName={'remove'}
                onClick={() => {
                  props.setContactInfo(props.contactInfo.filter((itm) => itm.value != item.value));
                }}
              />
            ),
          },
        ].slice(0, props.readonly ? 3 : 4)} // remove the "delete" column in readonly mode
        submitEdit={(item, column) => {
          const newItems = props.contactInfo.map((itm) => {
            if (itm == item) {
              if (column.header == 'Type') {
                return {
                  value: defaultValueForType(currentEditValue),
                  type: currentEditValue,
                  comment: itm.comment,
                };
              } else if (column.header == 'Value') {
                return {
                  value:
                    itm.type == SLACK
                      ? currentEditValue.startsWith('#')
                        ? currentEditValue
                        : '#' + currentEditValue
                      : currentEditValue,
                  type: itm.type,
                  comment: itm.comment,
                };
              } else {
                return {
                  value: itm.value,
                  type: itm.type,
                  comment: currentEditValue,
                };
              }
            }
            return itm;
          });
          props.setContactInfo(newItems);
          setCurrentEditValue(undefined);
        }}
      />
    </>
  );
};
